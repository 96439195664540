exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alpha-centauri-js": () => import("./../../../src/pages/alpha-centauri.js" /* webpackChunkName: "component---src-pages-alpha-centauri-js" */),
  "component---src-pages-civ-be-js": () => import("./../../../src/pages/civ-be.js" /* webpackChunkName: "component---src-pages-civ-be-js" */),
  "component---src-pages-civ-iv-js": () => import("./../../../src/pages/civ-iv.js" /* webpackChunkName: "component---src-pages-civ-iv-js" */),
  "component---src-pages-civ-v-js": () => import("./../../../src/pages/civ-v.js" /* webpackChunkName: "component---src-pages-civ-v-js" */),
  "component---src-pages-civ-vi-js": () => import("./../../../src/pages/civ-vi.js" /* webpackChunkName: "component---src-pages-civ-vi-js" */),
  "component---src-pages-compare-js": () => import("./../../../src/pages/compare.js" /* webpackChunkName: "component---src-pages-compare-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

